<template>

  <div class="app de">
    <div class="hd">
      <span @click="$router.back()">返回</span>
      <div>用户中心</div>
     <div></div>

    </div>
    <div class="ct">
      <div class="uct">
        <div class="avatar">
          <img :src="$store.state.userInfo.heardPhoto" alt="" v-if="$store.state.userInfo.heardPhoto">
          <img src="https://fakeimg.pl/300/"  v-else />
        </div>
        <div class="name">

          <template v-if="$store.state.userInfo.userName">  {{$store.state.userInfo.userName}}   </template>
          <template v-else-if="$store.state.userInfo.phone">  {{$store.state.userInfo.phone}}  </template>
          <template v-else-if="$store.state.userInfo.wxNickName">  {{$store.state.userInfo.wxNickName}}  </template>
          <template v-else>
            游客
            <div>
              <span @click="$router.push({name:'login'})">登录</span>
              <span @click="$router.push({name:'reg'})" style="margin-left: 15px;">注册</span>
            </div>

          </template>

          <!--          微信用户没有登出-->
          <template v-if="$store.state.userInfo.uid && !iswx">
            <div style="line-height: 2;" @click="dologout">退出登录</div>
          </template>


          <template v-if="$store.state.userInfo.state==0">
            <div style="line-height: 2;" @click="$router.push({name:'realname'})">立即实名验证 </div>
          </template>
          <template v-else-if="$store.state.userInfo.state==1">
            <div style="line-height: 2;">已通过实名 </div>
          </template>
          <template v-else-if="$store.state.userInfo.state==2">
            <div style="line-height: 2;">正在审核实名 </div>
          </template>
          <template v-else>
            <div style="line-height: 2;"> </div>
          </template>



        </div>
      </div>

      <md-field>

        <md-cell-item title="我的场馆预约" arrow @click="$router.push({name:'mybook'})" />
        <md-cell-item title="我的活动" arrow @click="$router.push({name:'myac'})" />
        <md-cell-item title="我的消息" arrow @click="$router.push({name:'msg'})" />

      </md-field>
    </div>



  </div>

</template>

<script>
export default {
name: "uc",
  data(){
    return {
      user:{
        phone:'',
        pwd:'',
        repwd:''
      }
    }
  },
  methods:{
    async doreg(){
          if(this.user.pwd !== this.user.repwd) return this.$toast.info('两次输入的密码不一致')
          if(!this.user.phone || this.user.phone.length!==11) return this.$toast.info('请填写正确的手机号码')
          const res = await this.$api.post('/mapi/reg',{ phone:this.user.phone,pwd:this.user.pwd})
          const { success,info } = res.data;
          if(success){
            this.$toast.succeed('注册成功')
            this.$router.replace({name:'login'})
          }else{
            this.$toast.failed(info)
          }


    }
  }

}
</script>

<style scoped lang="scss">
.uct{
  height: 300px;
  background: #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: center;
.avatar{
  height: 160px;
  width: 160px;
  border:1px solid #f4f4f4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
img{
  width: 120px;
  height: 120px;
  clip-path: circle(50%);
}
}
.name{
  color:#f4f4f4;
  margin-left: 50px;
  font-size: 26px;
}
}
</style>
